import React from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { RestaurantProvider } from "./restaurant-context";
import { PreferencesProvider } from './preferences-context';
import { CartProvider } from './cart-context';
import { FiltersProvider } from './filters-context';
import { TrackerProvider } from './tracker-context';

function AppProviders(props) {
    const { children } = props;

    return (
        <IonReactRouter>
            <RestaurantProvider>
                <TrackerProvider>
                    <PreferencesProvider>
                        <CartProvider>
                            <FiltersProvider>
                                {children}
                            </FiltersProvider>
                        </CartProvider>
                    </PreferencesProvider>
                </TrackerProvider>
            </RestaurantProvider>
        </IonReactRouter>
    );
}

export default AppProviders;
