import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import checkout from '../logic/checkout';
import { IonPage, IonContent } from '@ionic/react';
import { useTracker } from '../utils/contexts/tracker-context';
import { useRestaurant } from '../utils/contexts/restaurant-context';

function OrderSuccessPage() {
    const { trackDataFn } = useTracker();
    const { type, id } = useParams();
    const { t } = useTranslation();
    const { restaurant } = useRestaurant();

    useLayoutEffect(() => {
        async function storageCash() {
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (coupon.restaurantAccepted == restaurant.id) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {} }

                payload.order.network = "monticello";

                let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);

                await checkout.doPost(data);
            }
        }
        async function sendToDashboard() {
            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has("provider")) {
                let provider = urlParams.get("provider");
                if ("cash" == provider) {
                    storageCash();
                }
            }

            window.localStorage.removeItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (coupon) {
                window.sessionStorage.removeItem("coupon");
            }
        }

        sendToDashboard();
    }, []);

    return (
        <IonPage>
            <IonContent>
                <section id="success-page" className="relative pb-32 font-sans text-center">
                    <div>
                        <div className="bg-[#F6BE01]">
                            <h2 className="p-8 text-3xl">Ordine confermato</h2>
                        </div>
                        <div className="m-8">
                            <p className="font-bold">Il tuo ordine è in preparazione</p>
                            <p>Riceverai un messaggio appena sarà pronto</p>
                        </div>
                    </div>
                    <img src="/assets/images/checkout_success.png" className="w-1/2 m-auto pt-12" alt="checkout success" />

                    <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                        <a href={`/${type}/q/${id}/menu`} className="block py-4 rounded-full bg-[#F6BE01]">{t("successpage.button_1")}</a>
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}

export default OrderSuccessPage;
