import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import formats from '../../utils/formats';
import forms from '../../utils/forms';
import TimetablesOrder from './TimetablesOrder';

function TakeawayForm() {
    let storage = {};
    let submitName;

    const [payload, setPayload] = useState({ name: "", surname: "", time: new Date().toLocaleTimeString("it-IT", { hour: "2-digit", minute: "2-digit" }), phone: "" });

    const { number, products, quantity, table, setTable, total, customers, payed, setCustomer, setType, order_note, addOrderNote } = useCart();
    const history = useHistory();
    const { type, id } = useParams();
    const { restaurant } = useRestaurant();
    const { t } = useTranslation();

    function changeHandler(e) {
        let aux = e.target.value;
        if (e.target.name === "name") {
            setPayload(prevState => (
                { name: aux, surname: prevState.surname, time: prevState.time, phone: prevState.phone }));
        } else if (e.target.name === "surname") {
            setPayload(prevState => (
                { name: prevState.name, surname: aux, time: prevState.time, phone: prevState.phone }));
        } else if (e.target.name === "time") {
            setPayload(prevState => (
                { name: prevState.name, surname: prevState.surname, time: aux, phone: prevState.phone }));
        } else if (e.target.name === "phone") {
            setPayload(prevState => (
                { name: prevState.name, surname: prevState.surname, time: prevState.time, phone: aux }));
        }
    };

    function setSubmit(button) {
        submitName = button.target.id;
    }

    function submitForm(e) {
        e.preventDefault();
        if (submitName == "cash") {
            onClickHandler();
        }

        return false;
    }

    const onClickHandler = () => {
        let finalPrice = total;
        let coupon = window.sessionStorage.getItem("coupon");
        if (coupon) {
            coupon = JSON.parse(coupon);
            if (coupon.restaurantAccepted == restaurant.id) {
                finalPrice = formats.formatPercentageToPrice(coupon.discount, total);
            }
        }

        let amount = finalPrice * 100;
        let commissions = (finalPrice * 0.02) * 100;

        storage["restaurant"] = restaurant;
        storage["order"] = {
            "amount": amount,
            "commissions": commissions,
            "customers": "1",
            "customer": payload,
            "number": number,
            "payed": payed,
            "products": products,
            "quantity": quantity,
            "timestamp": Date.now(),
            "table": "-1",
            "total": finalPrice,
            "type": "takeaway",
            "note": order_note,
        };
        storage["transaction"] = {
            "id": forms.generateRandomID("cash_"),
            "provider": "cash",
        }
        window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));

        history.push(`/${type}/q/${id}/orders/awaitsms/?provider=cash`);
    };

    return (
        <form className="py-8" id="takeaway-form" onSubmit={submitForm}>
            <div className="pt-3 p-4">
                <div className="flex">
                    <div className="w-6 ml-3 mr-6">
                        <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-mycia-red" viewBox="0 0 690.59 690.9"><g data-name="Livello 2"><path d="M264.52 50.76c-45.26 5.35-65.36 33.43-66.71 75.47-.67 15.53-.71 33.62-.68 49.86.55 9.54-1.38 17.68 1.35 26.95-33.23 23.41-8.22 91.1 22.91 106.47C232.82 337 245.1 369.22 268.56 389v52.56C138.88 458.59-8.42 547.19.38 690.9l690-1.35c1.46-34.17-4.39-67.12-21.57-98.38-47.51-85.87-145.19-133.7-242.58-148.24V385c21.12-17.78 32.46-48.21 43.13-72.78 22.24-13.64 33.69-37.21 36.39-63.34 1.49-14.73-.16-36-12.13-44.47-.65-8.11 1.46-15.34.67-22.91-.92-7.19-2-16.88-1.35-24.93 4.67-51.24 33.95-60.64-6.74-110.51C455.32 11.36 405.73-.33 358.85.89c-14.4-3.05-33.67 2.51-44.47 4.72-22.78 5.47-37.14 20.88-45.82 41.11M305 65.58c10.2-15.64 21.13-19.83 35.62-23.47 4.95-1.24 11.05-2.46 16.15-2.31 11.73.34 18.34.33 31.11 2.87 22 3.93 43.37 8 60.64 22.91 8.46 7.57 16.45 17.38 18.2 29-.43 3.36-2.85 6.28-2.7 10.11-13.19 23.55-12 53.64-10.78 80.86.36 13.35-3.78 38.77-5.39 52.55h18.86c-.32 7.16-1 12-4 17.52-1.42 8.41-6.6 13.53-9.43 20.22l-14.83-1.35c-6.41 24.36-23.57 73.2-45.82 84.9l-8.08 8.09v109.04c-51.34 44.24-25.68 47.41-75.56.11h.01V368.81c-6.48-6.54-14.78-8.72-20.22-17.52-8.33-12.68-16.72-22.86-22.91-38.41-5.16-12.23-9.87-24.37-12.13-37.06l-12.12-1.35c-6.34-4.75-11.27-10.3-13.48-18.87-3.17-5.22-3.22-7.94-5.39-14.82l20.56 2c-4.39-26.94-6.75-54.51-5.74-81.55 1.56-23.72-4.56-52.82 14.15-64.69a109.29 109.29 0 0116.85-5.39 19 19 0 0014.15-2.7M43.5 650.47C61.86 551.44 171.89 501.6 264 483.21c110.46 102.51 53.13 102.71 163.4.27 92.29 15.75 198.41 69.75 219.82 165.64z" data-name="Livello 1" /></g></svg>
                    </div>
                    <p>Chi ritira</p>
                </div>
                <input type="text" name="name" id="name" className="w-full mt-2 py-2 px-4 rounded-lg bg-[#F2F2F2]" placeholder="Nome" onChange={changeHandler} required />
                <input type="text" name="surname" id="surname" className="w-full mt-2 py-2 px-4 rounded-lg bg-[#F2F2F2]" placeholder="Cognome" onChange={changeHandler} required />
                <div className="flex items-center py-6">
                </div>
                <div className="flex">
                    <div className="w-6 ml-3 mr-6">
                        <svg className="fill-current text-mycia-red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 691.9 691.76"><g data-name="Livello 2"><path d="M691.89 545.9c.37 16.34-8.44 53.44-15.22 68.26-24 47-120.77 87.72-177.25 75.36-32.41-4-72.7-21.73-102.63-32.16C240.17 599.9 75.8 430.82 25.58 270.94c-11.49-30.1-18.3-48.67-23.32-78.56C-10.16 136 30.53 38.92 77.63 15.12 96.82 7.77 135.91-4 156.19 1.37q8.85 2.94 26 37.32c18 30.91 34.44 66.16 55.73 96.24 7.52 11.6 13.62 19.25 14 31.42-6.49 31.56-53.8 54-74.87 77.58-13.37 14.2-16.89 19.1-11.55 33.64 26.08 54.27 63 107.39 102.13 146.56 36.37 37.56 86.62 70.07 136.51 98 4.34 2.14 15.94 6.94 21.11 6.63 34.22-6.46 63.63-84 100.17-88.87 13.91-.58 33.39 15.76 43.7 22.59 24.56 14.92 59.43 33.15 84 47.13 29.04 16.68 38.14 16.83 38.77 36.29z" data-name="Livello 1" /></g></svg>                    </div>
                    <p>Numero di telefono</p>
                </div>
                <div className="flex">
                    <input value="+39" className="float-left w-16 mr-6 mt-2 py-2 px-4 rounded-lg bg-[#F2F2F2]" disabled /><input type="tel" name="phone" id="phone" pattern="^((3[0-9][0-9]))(\d{7})$" className="float-left mt-2 py-2 px-4 rounded-lg bg-[#F2F2F2]" placeholder="0000000000" onChange={changeHandler} required />
                </div>
            </div>

            <div className="w-full flex flex-col justify-center px-4 py-2 bg-white">
                <div className="text-xs py-4"> <input type="checkbox" id="terms" name="terms" required />
                    <label className="pl-2">{t("formtable.lable_1.span_1")} <a className="underline" href="https://www.cartaidentitalimentare.com/privacy-esercizi-commerciali/" target="_blank">{t("formtable.lable_1.a_1")}</a> {t("formtable.lable_1.span_2")}</label>
                </div>
                <input type="submit" id="cash" value={t("tablespage.button_1")} className="block mt-10 py-4 font-bold rounded-full bg-[#F6BE01]" onClick={setSubmit}></input>
            </div>
        </form>
    );
}

export default TakeawayForm;
