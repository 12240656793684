import React from 'react';
import { IonContent, IonIcon, IonPage } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import TakeawayForm from '../components/cart/TakeawayForm';

function TakeawayOrderPage() {
    const history = useHistory();
    const { type, id } = useParams();

    const { t } = useTranslation();

    const onBackHandler = () => history.push(`/${type}/q/${id}/orders/`);

    return (
        <IonPage>
            <IonContent>
                <section id="takeaway-page" className="relative font-sans">
                    <div className="py-8 flex items-center border-b border-b-grey-200 bg-[#F6BE01]">
                        <IonIcon className="back-button absolute flex items-center ml-4 p-3 mb-6" onClick={onBackHandler} size="large" slot="icon-only" icon={arrowBack}></IonIcon>

                        <h2 className="pb-4 w-full text-center font-bold text-2xl">Ritiro ordine</h2>
                    </div>

                    <TakeawayForm />
                </section>
            </IonContent>
        </IonPage>
    );
}

export default TakeawayOrderPage;
