import React from 'react';
import Tabs from './Tabs';

function TabRouter(props) {
    const { hasOrders } = props;

    return (
        <Tabs hasOrders={hasOrders} />
    );
}

export default TabRouter;
