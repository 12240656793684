import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { IonModal } from '@ionic/react';
import DishModalContent from './DishModalContent';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

function DishModalTrigger(props) {
    const { dish, showDishModal, setShowDishModal, onDetailsOpenHandler } = props;

    const { t } = useTranslation();

    const onCloseHandler = () => setShowDishModal(false);

    return (
        <React.Fragment>
            <span onClick={onDetailsOpenHandler} className="dish-modal-trigger cursor-pointer underline text-sm text-black">{dish.has_nutritional_values ? t("dishmodaltrigger.span_1_b") : t("dishmodaltrigger.span_1_a")}</span>

            <IonModal isOpen={showDishModal} onDidDismiss={onCloseHandler}>
                <DishModalContent dish={dish} closerHandler={onCloseHandler} />
            </IonModal>
        </React.Fragment>
    );
}

export default DishModalTrigger;
