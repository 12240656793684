import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonModal } from '@ionic/react';
import Item from './Item';
import '../cart/cta.css';

function Cta(props) {
    const { product } = props;

    const { t } = useTranslation();

    const [showModal, setShowModal] = useState(false);

    const onOpenHandler = () => setShowModal(true);
    const onCloseHandler = () => setShowModal(false);

    return (
        <div className="cta py-2 border-t border-t-grey-200 text-center bg-[#F6BE01] rounded-b-lg">
            <button type="button" className="text-black w-full" onClick={onOpenHandler}>{t("cta.button_1")}</button>

            <IonModal isOpen={showModal} onDidDismiss={onCloseHandler} mode="md" css-class="custom-cta">
                <Item dish={product} closerHandler={onCloseHandler} />
            </IonModal>
        </div>
    );
}

export default Cta;
