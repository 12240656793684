import React, { useEffect } from 'react';
import { useParams } from "react-router";
import { useRestaurant } from '../../utils/contexts/restaurant-context';

function RestaurantLoader(props) {
    const { component: WrappedComponent } = props;
    const { id } = useParams();
    const { isLoading, restaurant, setRestaurant } = useRestaurant();

    useEffect(() => {
        async function loadRestaurant() {
            await setRestaurant(id);
        }

        loadRestaurant();
    }, [id]);

    return !isLoading ? (
        <WrappedComponent restaurant={restaurant} {...props} />
    ) : null;
}

export default RestaurantLoader;
