import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTracker } from '../../utils/contexts/tracker-context';
import './DeliveryPopupModalContentStyle.css';

function CallRestaurantModalContent(props) {
    const { onClickHandler, restaurant } = props;

    const { trackDataFn } = useTracker();
    const { t } = useTranslation();

    async function callHandler() {
        await trackDataFn(restaurant.id, restaurant.token, "phonecall", {}, restaurant.has_orders);
        // try {
        //     await window.fbq('track', 'Contact');
        // } catch (e) {
        //     console.log({ pixelError: e })
        // }
    }

    return (
        <div className="callrestaurant-modal-content overflow-auto text-black">
            {/* <div className="px-3 pt-6 text-center">
                <p>Se ordini telefonando perderai la possibilità di usufruire dello sconto online.</p>
            </div> */}
            <div onClick={() => callHandler()} className="text-center py-2 mx-8 mt-8 rounded-full border border-mycia-red">
                <a href={`tel:${restaurant.phone_number}`} onClick={onClickHandler}>Chiama</a>
            </div>
            <div className="text-center ">
                <button className="py-2 underline" onClick={onClickHandler}>Annulla</button>
            </div>
        </div>
    )
}

export default CallRestaurantModalContent;
