import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTracker } from '../../utils/contexts/tracker-context';
import { useTranslation } from 'react-i18next';
import Wisher from './Wisher';
import formats from '../../utils/formats';
import DrinkVariations from './DrinkVariations';
import DishVariations from './DishVariations';
import DishAdditions from './DishAdditions';
import BetterWith from './BetterWith';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

function Item(props) {
    const { dish, closerHandler } = props;
    const { trackDataFn } = useTracker();

    const { type, id } = useParams();
    const { restaurant } = useRestaurant();

    const [hasDrinkVariations, setHasDrinkVariations] = useState(false);
    const [hasDishVariations, setHasDishVariations] = useState(false);
    const [hasDishAdditions, sethasDishAdditions] = useState(false);
    const [drinkChanged, setDrinkChanged] = useState(false);
    const [dishChanged, setDishChanged] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [buyedSuggestedDrink, setBuyedSuggestedDrink] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const { t } = useTranslation();

    const { add } = useCart();
    const [note, addNote] = useState("");

    const changeHandler = ({ target }) => addNote(target.value);

    const addProduct = () => {
        async function track() {
            await trackDataFn(restaurant.id, restaurant.token, "addproduct", { "type": type, "product": dish.id, "price": dish.price, "name": dish.name, is_drink: dish.hasOwnProperty("cellar") }, restaurant.has_orders);
        }
        setErrorMessages([]);
        let error = false;
        (dish.additions != [] && dish.additions && dish.additions.categories) &&
            dish.additions.categories.forEach((addition, index) => {
                var aux = [];
                if (dish.use_additions && addition.mandatory_picks == true) {
                    aux = dish.use_additions.filter(addition =>
                        addition.categoryIndex == index
                    )
                    if (aux.length == 0) {
                        error = true;
                        setErrorMessages(prev => [...prev, `Attenzione, devi selezionare almeno un elemento di ${addition.name}`])
                    }
                }
            });

        if (!error) {
            dish.note = note;

            add(dish, quantity);

            if (buyedSuggestedDrink == true) {
                add(dish.suggested_drink, 1);
            }

            track();

            delete dish.use_glass_price;

            closerHandler();
        }
    };

    useEffect(() => {
        if (dish.glass_price && dish.glass_price != 0) {
            setHasDrinkVariations(true);
        }
        if (dish.variations && dish.variations.length != 0) {
            setHasDishVariations(true);
        }

        if (dish.additions && dish.additions.length != 0) {
            sethasDishAdditions(true);
        }
    }, []);

    return (
        <div className="pb-10 overflow-y-scroll">
            <div className="item relative overflow-y-scroll flex flex-col justify-between bg-white">
                <div className="flex flex-col py-4 border-b border-b-grey-200">
                    <div className="flex items-center px-4 pb-4 border-b border-b-grey-200">
                        <p className="flex-1 text-xl capitalize">{formats.capitalize(formats.truncate(dish.name, 20))}</p>
                        <p className="flex-none font-bold">{formats.formatPrice(dish.price)}</p>
                    </div>

                    {dish.image_path ?
                        <div className="px-3 py-1">
                            <img className="object-contain h-48 w-full" src={dish.image_path}></img>
                        </div>
                        : null
                    }

                    <div className="text-center p-4">
                        <p className="text-xl">{t("item.p_1")}</p>
                        <Wisher onQuantityHandler={setQuantity} />
                    </div>
                    {/* <input type="text" name="note" className="mt-2 py-2 mx-8 rounded-lg text-center bg-grey-200" placeholder="Aggiungi nota" onChange={changeHandler} /> */}

                    {dish.suggested_drink != undefined && dish.suggested_drink != "" && <BetterWith product={dish} buyedSuggestedDrink={buyedSuggestedDrink} setBuyedSuggestedDrink={setBuyedSuggestedDrink} />}

                    {hasDrinkVariations && <DrinkVariations product={dish} changed={drinkChanged} setChanged={setDrinkChanged} />}
                    {hasDishVariations && <DishVariations product={dish} changed={dishChanged} setChanged={setDishChanged} />}
                    {hasDishAdditions && <DishAdditions product={dish} errorMessages={errorMessages} />}

                </div>
            </div>
            <div className="flex flex-col p-4 bg-white">
                {dish.price == 0 ?
                    ((Object.values(dishChanged)[0] == true) || (dish.use_glass_price == true)) ?
                        <button type="button" onClick={addProduct} className="add-cart-item block py-2 rounded-full bg-[#F6BE01]">{t("item.button_1")}</button> :
                        null :
                    <button type="button" onClick={addProduct} className="add-cart-item block py-2 rounded-full bg-[#F6BE01]">{t("item.button_1")}</button>
                }
                <button type="button" onClick={closerHandler} className="block mt-2 py-2 rounded-full underline">{t("item.button_2")}</button>
            </div>
        </div>
    );
}

export default Item;


// --------------old for cycle---------------
  // for (const [idx, addition] of dish.additions.categories.entries()) {
        //     var aux = [];
        //     if (dish.use_additions && addition.mandatory_picks == true) {
        //         aux = dish.use_additions.filter(addition =>
        //             addition.categoryIndex == idx
        //         )
        //         if (aux.length == 0) {
        //             error = true;
        //             setErrorMessages(prev => [...prev, `Attenzione, devi selezionare almeno un elemento di ${addition.name}`])
        //         }
        //     }
        // }
