import React, { Fragment } from 'react';
import List from './List';
import DeliveryPopupModalTrigger from './modals/DeliveryPopupModalTrigger';

function Fork(props) {
    const { restaurant } = props;

    return (
        restaurant &&
        <Fragment>
            {/* {restaurant.token == "C6jauF3Sn" && <DeliveryPopupModalTrigger />} */}
            <List restaurant={restaurant} />
        </Fragment>
    );
}

export default Fork;
