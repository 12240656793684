import React, { useState } from 'react';
import { useParams } from 'react-router';
import AllergenIcons from '../UI/AllergenIcons';
import Cta from '../cart/Cta';
import DrinkModalTrigger from '../modals/DrinkModalTrigger';
import PreferredButton from '../UI/PreferredButton';
import assets from '../../utils/assets';
import formats from '../../utils/formats';
import { useRestaurant } from '../../utils/contexts/restaurant-context';
import { useTracker } from '../../utils/contexts/tracker-context';
import clsx from 'clsx';

function Drink(props) {
    const { index, drink } = props;

    const { restaurant } = useRestaurant();
    const { type } = useParams();
    const { trackDataFn } = useTracker();

    const [showDrinkModal, setShowDrinkModal] = useState(false);

    let icons = [];
    let name = drink.name; //formats.formatDrinkName(drink);
    let description = formats.formatDescription(drink);

    (drink.has_sulfites) && icons.push(assets.assetsPath("images/icons/allergie/solfiti.png"));

    const onDetailsOpenHandler = async function handleClick() {
        await trackDataFn(restaurant.id, restaurant.token, "drinkview", { "type": type, "name": drink.name, "is_campari": drink.is_campari, "id": drink.id, "price": drink.price }, restaurant.has_orders);
        // try {
        //     await window.fbq("track", "ViewContent");
        // } catch (e) {
        //     console.log({ pixelError: e })
        // }
        // finally {
        setShowDrinkModal(true);
        // }
    };

    return (
        <div className="drink p-2">
            <div className={clsx("drink-content flex flex-col rounded-lg shadow", { "bg-grey-100": formats.isEven(index), "bg-white": !formats.isEven(index) })}>
                <div className="flex">
                    {drink.image_path != "" ? <img className="object-cover w-32 h-32 m-2 border border-grey-300 rounded-xl" src={drink.image_path} onClick={onDetailsOpenHandler}></img> : null}

                    <div className="flex-1 py-2 px-4">

                        <p className="drink-content-name capitalize font-semibold">{name}</p>

                        <p className="text-sm">{description}</p>

                        <AllergenIcons icons={icons} /> {formats.shouldDisplayDrinkDetails(drink) ? <DrinkModalTrigger drink={drink} showDrinkModal={showDrinkModal} setShowDrinkModal={setShowDrinkModal} onDetailsOpenHandler={onDetailsOpenHandler} /> : null}

                        <p className="mt-2">{formats.formatPrice(drink.price)}</p>
                        <p className="mt-2">{drink.glass_price != 0 ? "Calice: " + formats.formatPrice(drink.glass_price) : ""}</p>
                    </div>
                    <div className="flex flex-col items-end py-2 pr-4 text-base">
                        <PreferredButton item={drink} drink={true} />
                    </div>
                </div>

                {formats.shouldDisplayCta(type, restaurant.orders) ? <Cta product={drink} /> : null}
            </div>
        </div>
    );
}

export default Drink;
