import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import IngredientList from '../UI/IngredientList';
import formats from '../../utils/formats';
import dl from '../../utils/device-language';
import assets from '../../utils/assets';
import { warning, chevronBackOutline } from 'ionicons/icons';

function DishModalContent(props) {
    const { dish, closerHandler } = props;

    const { t } = useTranslation();

    function Nutrients() {
        let nutritionalValues = [];
        for (const key in dish.nutritional_values) {
            nutritionalValues.push({ key: key, value: dish.nutritional_values[key] });
        }

        return (
            <dl className="pb-4">
                {nutritionalValues.map((nv, i) => {
                    return (
                        <div className="flex justify-between" key={i}>
                            <dt className="capitalize">{nv.key.replace("_", " ")}</dt>
                            <dd>{nv.value}<span className="text-sm text-grey-600">g / 100g</span></dd>
                        </div>
                    );
                })}
            </dl>
        );
    }

    return (
        <div className="dish-modal-content text-black mx-auto pt-8 pb-16 overflow-y-scroll">
            <div>
                <div className="relative mb-4 px-4" style={{ "minHeight": "2rem" }}>
                    {dish.has_video == true ?
                        <iframe src={dish.video_url} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                        :
                        dish.image_path && "" !== dish.image_path ? <img src={dish.image_path} alt={t("dishmodalcontent.img_1")} className="object-cover w-full mb-4 shadow rounded-lg" />
                            :
                            null
                    }

                    <button type="button" onClick={closerHandler} className="z-10 absolute top-0 left-0 flex items-center justify-center ml-2 mt-4 p-3 rounded-full shadow-lg bg-white">
                        <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                    </button>
                </div>
            </div>

            <div className="py-4 px-8">
                <p className="mb-6 py-4 text-2xl font-medium capitalize border-b border-b-gray-600">{formats.capitalize(dish.name)}</p>

                {dish.description.length && (dl.isCleanDeviceLanguage("it-IT") || dl.isCleanDeviceLanguage("it")) ? <p className="pb-6">{dish.description}</p> : null}

                <p className="mb-4 font-medium">{t("dishmodalcontent.p_1")}</p>
                <ul className="ingredients-list mb-6 list-disc list-inside">
                    {dish.ingredients.map((ingredient, i) => <IngredientList ingredient={ingredient} key={i} />)}
                </ul>

                {dish.has_nutritional_values == true &&
                    <div>
                        <p className="font-bold">Valori nutrizionali</p>

                        <Nutrients />
                    </div>}

                {formats.shouldDisplayFilterIcon(dish) ?
                    <div className="flex items-center justify-center py-6 px-4 border-t border-t-gray-200">
                        <img src={dish.icon == "images/icons/menu/ic_tick_gray.png" ? assets.assetsPath("svg/legend/triangolo_grigio.svg") : assets.assetsPath(dish.icon)} className="w-8 flex-none mr-4 fill-current text-mycia-red"></img>
                        <div>
                            <p className="text-sm">{dish.user_suggestion}</p>
                        </div>
                    </div>
                    : null}

                {dish.has_allergens ?
                    <div className="allergens-warning flex items-center justify-center py-6 px-4 border-t border-t-gray-200">
                        <IonIcon icon={warning} size="large" className="flex-none mr-4 fill-current text-mycia-red"></IonIcon>
                        <div>
                            <p className="text-sm"><span>{t("dishmodalcontent.p_2.span_1")}</span> ({t("dishmodalcontent.p_2.span_2")} <span className="text-mycia-red">{t("dishmodalcontent.p_2.fragment_1")}</span>).</p>
                        </div>
                    </div>
                    : null}

                {dish.is_frozen ?
                    <div className="frozen-warning flex items-center justify-center py-6 px-4 border-t border-t-gray-200">
                        <IonIcon icon={warning} size="large" className="flex-none mr-4 text-mycia-red"></IonIcon>
                        <div>
                            <p className="text-sm">{t("dishmodalcontent.p_3")}</p>
                        </div>
                    </div>
                    : null}
            </div>
        </div>
    );
}

export default DishModalContent;
