function getCustomElement(id, element, t) {
    if (element === "splash") {
        if (id === 9440 || id === 10158) {
            return "Drinklist";
        } else {
            return t("splash.button_1");
        }
    }
    if (element === "newtabs") {
        if (id === 9440 || id === 10158) {
            return "Drinklist";
        } else if (id === 9540) {
            return "BEVANDE";
        } else {
            return t("tab.button_2");
        }
    }
    if (element === "preferreddefaultscreen") {
        if (id === 9440 || id === 10158) {
            return "Premi sull'icona della stella per aggiungere un drink a questa lista. Ritroverai tutti i drink salvati in questa sezione, potendo scegliere tra i tuoi preferiti nel momento della creazione dell'ordine. Premi nuovamente sulla stella per rimuovere un preferito da questa lista.";
        } else {
            return t("preferreddefaultscreen.p_2");
        }
    }
}

export default {
    getCustomElement,
}
