import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

/** Pages */
import SplashPage from './pages/SplashPage';
import MenuPage from './pages/MenuPage';
import ErrorPage from './pages/ErrorPage';
import OrdersPage from './pages/OrdersPage';
import OrdersErrorPage from './pages/OrdersErrorPage';
import OrdersCancelPage from './pages/OrdersCancelPage';
import TakeawayOrderPage from './pages/TakeawayOrderPage';
import OrderSuccessPage from './pages/OrderSuccessPage';
import GuestSharingSuccessPage from './pages/GuestSharingSuccessPage';

function AppRoutes() {
    return (
        <IonRouterOutlet>
            <Route exact path="/">
                <SplashPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/awaitsms">
                <OrderSuccessPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/takeaway">
                <TakeawayOrderPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/error">
                <OrdersErrorPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/cancel">
                <OrdersCancelPage />
            </Route>
            <Route exact path="/:type/q/:id/orders">
                <OrdersPage />
            </Route>
            <Route exact path="/:type/q/:id/menu">
                <MenuPage />
            </Route>
            <Route exact path="/:type/q/:id/orders/sharesuccess">
                <GuestSharingSuccessPage />
            </Route>
            <Route path="/404">
                <ErrorPage />
            </Route>
            <Redirect to="/404" />
        </IonRouterOutlet>
    );
}

export default AppRoutes;
