
function publicPath(filepath) {
    return String(filepath).startsWith("/") ?
        filepath.substr(0, 1) :
        filepath || "";
}

function assetsPath(filepath) {
    return String(filepath).startsWith("/") ?
        String.prototype.concat("assets", filepath) :
        String.prototype.concat("assets", "/", filepath) || "";
}

function assetsNamespacedPath(filepath) {
    let namespace = process.env.REACT_APP_NAMESPACE || "mycia";

    return String(filepath).startsWith("/") ?
        assetsPath(String.prototype.concat(namespace, filepath)) :
        assetsPath(String.prototype.concat(namespace, "/", filepath)) || "";
}

function assetsLogoPath(id) {
    let path = assetsNamespacedPath("logo.png");
    if (String(id).match("1027")) {
        path = assetsPath("espolon/logo.png");
    }
    if (String(id).match("8383")) {
        path = assetsPath("bluenote/logo.jpeg");
    }
    return path;
}

export default {
    publicPath,
    assetsPath,
    assetsNamespacedPath,
    assetsLogoPath,
}
