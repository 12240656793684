import React from 'react';
import { useTranslation } from 'react-i18next';

function PoweredBy() {
    const { t } = useTranslation();

    return (
        <div className="powered-by w-full m-0 py-16 px-4">
            <p className="text-center">
                {t("poweredby.p_1.fragment_1")}
                <a href="https://mycia.it" className="cursor-pointer ml-2 font-bold underline">{t("poweredby.p_1.a_1")}</a>
            </p>
        </div>
    );
}

export default PoweredBy;
