import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { arrowForward } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

function Splash(props) {

    const history = useHistory();
    const { t } = useTranslation();

    async function handler(path) {
        if (path == "bistrot") {
            history.push("/t/q/ffnjDuqPE/menu");
        } else if (path == "poolbar") {
            history.push("/t/q/C6jauF3Sn/menu");
        }
    }

    return (
        <div className="splash pb-16">
            <div className="px-8 py-10 space-y-8 bg-white">
                <img src="/assets/monticello/logo1.png" className="w-1/2 mx-auto" />
                <img src={"/assets/monticello/logo.png"} alt={t("splash.img_1")} className="w-full" />
            </div>

            <div className="relative py-6 px-4 text-center">
                {/* <button type="button" onClick={() => handler("bistrot")} className="py-3 px-8 my-8 w-full flex items-center justify-between cursor-pointer rounded-full bg-white shadow-xl">
                    <p>Bistrot</p>
                    <div className="flex w-10 h-10 items-center justify-center rounded-full bg-[#FEDB01]">
                        <IonIcon className="w-6 h-6 text-black" icon={arrowForward} />
                    </div>
                </button> */}

                <button type="button" onClick={() => handler("poolbar")} className="py-3 px-8 my-8 w-full flex items-center justify-between cursor-pointer rounded-full bg-white shadow-xl">
                    <p>PoolBar</p>
                    <div className="flex w-10 h-10 items-center justify-center rounded-full bg-[#FEDB01]">
                        <IonIcon className="w-6 h-6 text-black" icon={arrowForward} />
                    </div>
                </button>
            </div>
        </div>
    );
}

export default Splash;
